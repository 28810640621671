@import '../../../../../assets/scss/_base.scss';
.accountList {
  margin: 0px;
  padding: 0px;
}

.listItem {
  padding: 12px 0px;
  display: flex;
  align-items: center;
  list-style: none;
  border-bottom: 1px solid #E7EBEF;
}

.listItemMeta {
  flex: 1;
}

.listItemAction {
  flex: 0 0 auto;
  margin-left: 48px;
}

.listItemTitle {
  margin-bottom: 5px;
  color: $primaryColor;
}

.listItemdescription {
  color: $primaryColor;
}

.btn {
  color: $subPrimaryColor;

  &:hover {
    color: $subPrimaryColor;
  }
}