@import '../../../../assets/scss/_base.scss';

.footerBar {
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 40px;
  padding-right: 24px;
  width: calc(100% - 220px);
  height: 48px;
  background-color: #FFFFFF;
}

.actions {
  padding-left: 16px;
}

.deleteLinkBtn {
  margin-left: 8px;
  color: $primaryColor;
}

.contents {
  display: flex;
  align-items: center;
  height: 48px;
  font-size: 14px;
  color: #333333;
}

.FootMoney {
  font-size: 24px;
  font-weight: bold;
  color: #FF4B4A;
}