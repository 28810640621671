@import '../../../../assets/scss/_base.scss';
.formbtn {
  float: right;
  margin-top: 20px;

  Button {
    margin-left: 10px;
  }
}

.tabs {
  border-top: 1px solid #D5D9DB;
  border-left: 1px solid #D5D9DB;

  td {
    border-bottom: 1px solid #D5D9DB;
    border-right: 1px solid #D5D9DB;
  }
}

.tabsthead {
  background: #FAFAFA;
  opacity: 1;
  height: 40px;
}

.tCK {
  width: 80px;
  text-align: center;
}

.tGoodsinfo {
  width: 340px;
  padding-left: 16px;
}

.tGoodsprice {
  width: 400px;
  padding-left: 16px;
}

.tGoodsnum,
.Lgoodsnum {
  padding: 0px 10px;
  text-align: center;
}

.tGoodsmoney {
  width: 200px;
  text-align: center;
}

.tGoodsOp {
  padding-left: 26px;
}

.tdminH {
  height: 120px
}

.LtCK {
  width: 40px;
  text-align: center;
}

.Lgoodsinfo {
  padding-left: 16px;

}

.Lgoodsprice {
  padding-left: 16px;
}

.Lgoodsmoney {
  text-align: center;
}

.LgoodsOp {
  padding-left: 10px;
  padding-right: 10px;

  a {
    cursor: pointer;
  }
}

.bstylesW {
  height: 15px;
  line-height: normal;
  box-sizing: content-box;
  margin: 0 auto;
}

.borderstyles {
  border-radius: 2px;
  padding: 2px;
  border: 1px #3F61F6 solid;
  color: #3F61F6;
  text-align: center;
  font-size: 12px;
}

.borderstylesRed {
  border-radius: 2px;
  padding: 2px;
  color: #FF4B4A;
  border: 1px #FF4B4A solid;
  text-align: center;
  font-size: 12px;
}

.goodsBaseItem {
  display: flex;
}

.imgBox {
  display: flex;
  align-items: center;
  margin-right: 10px;
  width: 80px;
  height: 80px;

  .pic {
    display: block;
    width: 80px;
    background-size: 100% 100%;
  }
}

.ttitle {
  font-size: 14px;
  font-family: MicrosoftYaHei;
  line-height: 17px;
  color: #333333;
  margin: 2px 0px;
  opacity: 1;

  .linkText:hover {
    color: $errorColor;
  }
}

.tfont {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #C1C2C5;
  opacity: 1;
}

.supplyList {
  width: 80%;
  height: 30px;
  margin: 10px 0px;
  border-bottom: 1px #E7EBEF dashed;
}

.supplyListprice {
  display: inline-block;
  min-width: 100px;
  margin: 0px 10px;
  font-size: 12px;
  font-family: MicrosoftYaHei;
}

.supplyListstock {
  width: 100px;
  display: inline-block;
  margin: 0 auto;
  color: $warningColor;
  font-size: 12px;
  font-family: MicrosoftYaHei;
}

.supplyNum {
  height: 30px;
  margin: 10px 0px;
}

.stockErr {
  margin: 10px 0px;
  color: $errorColor;
}

.GoodsAccount {
  background: #FAFAFA;
  height: 46px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.FootCK {
  display: inline-block;
  width: 46PX;
  text-align: center;
}

.FootM {
  display: inline-block;
  margin: 0px 10px;
}

.FootOp {
  display: flex;
  align-items: center;
}

.FootMoney {
  display: inline-block;
  height: 46px;
  line-height: 46px;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #FF4B4A;
  opacity: 1;
  margin-right: 30px;
}

.epmtd {
  padding-top: 32px;
  padding-bottom: 24px;
}