// 主题色
$primaryColor: #204F7A;

// 副主题色
$subPrimaryColor: #204F7A;

// 成功色
$successColor: #21BB7E;

// 警告色
$warningColor: #FF7900;

// 错误红色
$errorColor: #FF4B4A;

// 必填*号颜色
$requiredColor: #F5222D;

// 边线颜色
$borderBaseColor: #D5D9DB;

// 禁止颜色
$disabledColor: #C1C2C5;