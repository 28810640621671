@import '../../../../assets/scss/_base.scss';
.lineTitle {
  margin-right: 8px;
  color: #333333;
  font-size: 14px;
  font-weight: 600;
}

.lineTime {
  margin-top: 8px;
  margin-bottom: 0px;
}

.pictureWrap {
  display: inline-block;
  margin-left: 10px;
  vertical-align: top;
  zoom: 1;

  &:first-child {
    margin-left: 0px;
  }
}