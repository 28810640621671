@import '../../../assets/scss/_base.scss';
.tabsLand {
  margin-bottom: 16px;
  padding-top: 12px;
  background-color: #ffffff;

  :global {
    .ant-tabs-bar {
      margin-bottom: 0px;
    }
  }
}