@import '../../../../assets/scss/_base.scss';

.GoodsAccount {
  margin-top: 24px;
  background: #FAFAFA;
  height: 46px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.FootCK {
  display: inline-block;
  width: 40PX;
  text-align: center;
}

.FootM {
  display: inline-block;
  margin: 0px 25px 0px 0px;
}

.removeMain:hover {
  cursor: pointer;
  color: $primaryColor;
}

.FootOp {
  display: flex;
  align-items: center;
}

.FootMoney {
  display: inline-block;
  height: 46px;
  line-height: 46px;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #FF4B4A;
  opacity: 1;
  margin-right: 30px;
}