@import '../../../../assets/scss/_base.scss';
.tabs {
  td {
    border: 1px solid #D5D9DB;
  }
}

.tGoodsimg {
  width: 110px;
  text-align: center;
}

.tabsthead {
  background-color: #FAFAFA;
  opacity: 1;
  height: 40px;
}

.tCK {
  width: 100px;
  text-align: center;
}

.tGoodsinfo {
  width: 400px;
  padding-left: 16px;
}

.tGoodsprice, .LgoodsPrice {
  width: 400px;
  padding-left: 24px;
}

.LgoodsPriceCell, .LgoodsPrepaymentRatio {
  display: inline-block;
  width: 160px;
  vertical-align: middle;
}

.LgoodsPrepaymentRatio {
  margin-left: 12px;
}

.tGoodsnum,
.Lgoodsnum {
  width: 120px;
  text-align: center;
}

.tGoodsmoney, .LgoodsMoney {
  text-align: center;
}

.tGoodsOp, .LgoodsOp {
  text-align: center;
}

.tdminH {
  height: 120px
}

.LtCK {
  text-align: center;
}

.Lgoodsinfo {
  padding-left: 16px;

  .GoodsInfo {
    display: flex;
    align-items: center;
  }
}

.Lgoodsprice {
  padding-left: 20px;
}

.Lgoodsmoney {
  text-align: center;
}

.GoodsImgOne {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;

}

.GoodsInfoimg {
  width: 90px;
  height: 90px;
  background-color: #F9F9F9;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

}

.Goodsimgs {
  display: flex;
  width: 80px;
  height: 80px;
  background-size: 100% 100%;

}


.bstylesW {
  height: 15px;
  line-height: normal;
  box-sizing: content-box;
  margin: 0 auto;
}

//////////@at-root
.borderstyles {
  border-radius: 2px;
  padding: 2px;
  border: 1px #3F61F6 solid;
  color: #3F61F6;
  text-align: center;
  font-size: 12px;
}

.borderstylesRed {
  border-radius: 2px;
  padding: 2px;
  color: #FF4B4A;
  border: 1px #FF4B4A solid;
  text-align: center;
  font-size: 12px;
}

.footset {
  padding: 12px 12px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: right;
  border-left: 1px solid #D5D9D8;
  border-right: 1px solid #D5D9D8;
  border-bottom: 1px solid #D5D9D8;
  opacity: 1;
}

.costmoeny {
  width: 96px;
  height: 31px;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 17px;
  color: $errorColor;
  opacity: 1;
}