@import '../../assets/scss/_base.scss';
.loginWrap {
  display: block;
  position: relative;
  height: 100vh;
  background-image: url('~@/assets/images/login/login_background.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}

.header {
  padding-top: 76px;
  width: 100%;
}

.logo {
  margin-left: 100px;
  display: block;
  width: 292px;
  height: 48px;
}

.content {
  display: flex;
  align-items: center;
  margin-left: 100px;
  margin-right: 156px;
}

.bannerBox {
  flex: 1;
  color: #FFFFFF;
}

.bannerTitle {
  margin-bottom: 24px;
  font-size: 58px;
  font-weight: bold;
  color: #FFFFFF;
  letter-spacing: 10px;
  line-height: normal;
}

.bannerDesc {
  margin-bottom: 24px;
  font-size: 58px;
  letter-spacing: 10px;
}

.bannerExplain {
  margin-bottom: 0px;
  font-size: 30px;
  letter-spacing: 4px;
}

.section {
  padding: 139px 38px 0px 38px;
  width: 558px;
  height: 714px;
  background-color: #ffffff;
  border-radius: 6px;
}

.sectionTitle {
  margin-bottom: 50px;
  color: $subPrimaryColor;
  font-size: 45px;
  font-weight: bold;
  text-align: center;
  line-height: normal;
}

.loginBtn {
  height: 56px;
  background-color: $subPrimaryColor;

  &:hover, &:active, &:focus {
    background-color: $subPrimaryColor;
  }
}

.footer {
  padding: 12px 0px;
  background-color: #181A1A;
  text-align: center;
  color: #999999;

  a {
    color: #999999;

    &:hover {
      color: #999999;
    }
  }
}
