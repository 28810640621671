@import '../../assets/scss/_base.scss';
.container {
  position: relative;

  .bounceLand {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 80px;
    text-align: center;

    .letter {
      animation: bounce 0.75s cubic-bezier(0.05, 0, 0.2, 1) infinite alternate;
      display: inline-block;
      transform: translate3d(0, 0, 0);
      margin-top: 0.5em;
      text-shadow: rgba(255, 255, 255, 0.4) 0 0 0.05em;
      font-size: 18px;
      color: #1890FF;
    }

    .letter:nth-child(1) {
      animation-delay: 0s;
    }

    .letter:nth-child(2) {
      animation-delay: 0.0833333333s;
    }

    .letter:nth-child(3) {
      animation-delay: 0.1666666667s;
    }

    .letter:nth-child(4) {
      animation-delay: 0.25s;
    }

    .letter:nth-child(5) {
      animation-delay: 0.3333333333s;
    }

    .letter:nth-child(6) {
      animation-delay: 0.4166666667s;
    }
  }

  .pic {
    display: block;
    width: 100%;
  }

  .copyRight {
    position: absolute;
    bottom: 20px;
    left: 0px;
    right: 0px;
    text-align: center;
    color: #999999;
  }
}

@keyframes bounce {
  0% {
    transform: translate3d(0, 0, 0);
    text-shadow: rgba(255, 255, 255, 0.4) 0 0 0.05em;
  }

  100% {
    transform: translate3d(0, -1em, 0);
    text-shadow: rgba(255, 255, 255, 0.4) 0 1em 0.35em;
  }
}