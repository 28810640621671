@import '../../assets/scss/_base.scss';
.scrond {
  height: 422px;
  border: 1px solid #E8E8E8;
  align-items: center;
  margin-top: 10px;
}

.scrond_label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 10px;
  color: #333333;
  font-weight: bold;
}

.scrond_info {
  width: 960px;
  height: 311px;
  margin: 5px 0px;
}

.scron_timeline {
  height: 311px;
  overflow-y: auto;
  padding-left: 100px;
  margin-top: 22px;
  /*火狐浏览器修改滑动条*/
  scrollbar-width: thin;
  scrollbar-color: #d9d9d9;

  :global {
    .ant-timeline {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      color: rgba(0, 0, 0, 0.65);
      font-size: 14px;
      font-variant: tabular-nums;
      line-height: 1.5;
      -webkit-font-feature-settings: 'tnum';
      font-feature-settings: 'tnum', "tnum";
      margin: 20px 150px;
      padding: 0;
      list-style: none;
    }

    .ant-timeline-item {
      position: relative;
      margin: 0;
      padding: 0 0 35px;
      padding-top: 10px;
      font-size: 14px;
      list-style: none;
      top: 5px;
    }

    .ant-timeline-item-content {
      position: relative;
      top: -14px;
      margin: 0 0 0 18px;
      word-break: break-word;
    }
  }
}

.scron_timeline::-webkit-scrollbar {
  width: 5px;
  height: 2px;
  background-color: #f5f5f5;
}

.scron_timeline::-webkit-scrollbar-thumb {
  background-color: #b8b3b3;
  border-radius: 5px;
}

.scrond_info_time {
  width: 100px;
  position: absolute;
  left: -100px;
  height: 100px;
  line-height: 20px;
  text-align: center;
}