@import '../../../../assets/scss/_base.scss';
//错误
.errorModal {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #333333;
  opacity: 1;
}

.errorDesc {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #C1C2C5;
  opacity: 1;
}