@import '../../../assets/scss/_base.scss';
.filterLand {
  position: relative;
  margin-bottom: 15px;
  height: 56px;
  overflow: hidden;
  border-bottom: 1px solid #E8E8E8;
}

.collapseButton {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
}

.collapseOpen {
  height: auto;
  border-bottom: none;
}