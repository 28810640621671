@import '../../../../assets/scss/_base.scss';
.formitemRow {
  :global {
    .ant-form-item {
      display: flex;
    }

    .ant-form-item-control-wrapper {
      flex: 1;
    }
  }
}
