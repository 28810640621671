body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6, p, ul, li {
  margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
  list-style: none;
}

.ant-table-thead > tr > th,
.ant-table-tbody> tr > td {
  padding: 8px 10px;
  font-size: 14px;
}

.ant-table-thead > tr > th {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}

.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td,
.ant-table-row-hover,
.ant-table-row-hover > td,
.ant-table-fixed .ant-table-row-hover {
  background-color: transparent !important;
}

.ant-pagination-item-active {
  background-color: #333333;
}

.ant-pagination-item-active a, 
.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a {
  color: #FFFFFF;
}

.ant-tabs-nav .ant-tabs-tab-active {
  color: #333333;
  font-weight: 600;
}

.ant-btn-danger {
  background-color: #FF4B4A;
}
 

.clearfix {
  zoom: 1;
}

.clearfix::after {
  content: '';
  height: 0;
  line-height: 0;
  display: block;
  visibility: hidden;
  clear: both;
}

.redColor {
  color: #FF4B4A;
}

.disableBackground {
  background-color: rgba(51, 51, 51, 0.05);
}

.required {
  position: relative;
}

.required::before {
  display: inline-block;
  margin-right: 4px;
  color: #F5222D;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.hideRequired::before {
  color: transparent
}

.label {
  position: relative;
}

.label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}

.loading-wrapper {
  display: none;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding-top: 100px;
  text-align: center;
  background-color: rgba(230, 247, 255, 0.45);
  z-index: 999;
}