@import '../../../../assets/scss/_base.scss';
.colTitle {
  position: relative;
  margin-bottom: 10px;
  padding-right: 10px;
  height: 32px;
  line-height: 32px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  .delete {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0px;
    font-size: 12px;
  }

  .colLabel {
    max-width: 70px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .colControl {
    margin-left: 10px;
    flex: 1;
  }
}

.listWrapper {
  padding: 0px;
  margin: 0px;
  border-top: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8;
}

.listItem {
  padding: 0px 5px;
  height: 30px;
  line-height: 30px;
  border-bottom: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}