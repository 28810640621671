@import '../../../../assets/scss/_base.scss';
.textAreaBox{
  position: relative;
}

.textAreaCount {
  position: absolute;
  bottom: 0px;
  right: 20px;
  color: #333333;
}