@import '../../../../../assets/scss/_base.scss';
.settingsWrap {
  margin-top: 12px;
  width: 400px;
}

.land {
  display: flex;
  align-items: center;
}

.content {
  flex: 1;
}

.action {
  flex: 0 0 auto;
}

.label {
  position: relative;

  &::after {
    content: ':';
    position: relative;
    top: -0.5px;
    margin: 0 8px 0 2px;
  }
}

.sendBtn {
  padding: 0px;
}