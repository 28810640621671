@import '../../assets/scss/_base.scss';
.invoiceAmount {
  :global {
    .ant-descriptions-item-label, .ant-descriptions-item-content {
      padding: 12px;
    }
  }

  p {
    margin-bottom: 0px;
  }
}