@import '../../assets/scss/_base.scss';
.main {
  position: relative;
}

.title {
  margin-bottom: 15px;
  font-size: 14px;
  color: #333333;
  font-weight: bold;
}

.btn {
  position: absolute;
  right: 20px;
  bottom: 35px;

  a {
    margin: 0px 5px;
  }
}
