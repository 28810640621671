@import '../../../../assets/scss/_base.scss';
.uploadLand {
  height: 440px;

  :global {
    .ant-upload.ant-upload-drag {
      background-color: #ffffff;
    }
  }
}

.title {
  margin-bottom: 10px;
  font-size: 16px;
  color: #333333;

  &.hidden {
    visibility: hidden;
  }
}

.draggerContent {
  display: flex;
  align-items: center;
  height: 268px;

  p {
    font-size: 14px;
    color: #333333;
  }

  .tipText {
    color: #FF4D4F;
  }

  .imgBox {
    margin-bottom: 16px;

    .pic {
      display: block;
      margin: 0px auto;
      width: 135px;
    }
  }
}

.operateBtns {
  text-align: right;

  .operateBtn {
    padding: 0px;
    margin-left: 12px;
    width: 130px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    background-color: #ffffff;
    font-size: 14px;
    color: #333333;
    border-color: #d9d9d9;
    border-radius: 2px;

    &:first-child {
      margin-left: 0px;
    }

    &.primary {
      color: #ffffff;
      background-color: #0169C6;
    }
  }
}