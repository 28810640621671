@import '../../../../assets/scss/_base.scss';
.bottomBordered {
  border-bottom: 1px solid $borderBaseColor;
}

.imgBox {
  display: block;
  padding: 8px;
  width: 104px;
  height: 104px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;

  .pic {
    display: block;
    width: 86px;
    height: 86px;
  }
}

.btns {
  margin-top: 24px;
  text-align: center;
}