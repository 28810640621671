@import '../../../../assets/scss/_base.scss';
.btns {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background-color: #FFFFFF;
  text-align: right;
}