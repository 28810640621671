@import '../../../../assets/scss/_base.scss';
.UsInfo {
  margin: 20px 0px;
  height: 90px;
  background: #F3F6FF;
  display: flex;
  align-items: center;
  justify-content: space-between;

  li {
    margin: 0px 30px;
    list-style: none;
  }

  p {
    margin-top: 10px;
    margin-bottom: 0px;
  }
}

.UsInfoCRed {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 17px;
  color: $errorColor;
}

.goodsAdress {
  height: 46px;
  background-color: #FAFAFA;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.costTitle {
  margin-right: 10px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
}

.costM {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: $errorColor;
}

.footBtn {
  margin-top: 20px;
  text-align: right;
}