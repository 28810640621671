@import '../../../../assets/scss/_base.scss';
.formbtn {
  float: right;
  margin-top: 20px;

  Button {
    margin-left: 10px;
  }
}

.CardStyles {
  :global {
    .ant-card-body {
      padding: 12px;
      zoom: 1;
    }
  }
}

.pagintion {
  margin-top: 20px;
  float: right;
  display: flex;
  align-items: center;
}

.tabs {
  td {
    border: 1px solid #D5D9DB;
  }
}

.tabsthead {
  background: #FAFAFA;
  opacity: 1;
  height: 40px;
}

.checkBox {
  width: 40px;
  text-align: center;
}

.tCK {
  width: 80px;
  text-align: center;
}

.tGoodsinfo {
  width: 400px;
  padding-left: 20px;
}

.tGoodsprice {
  width: 400px;
  padding-left: 20px;
}

.tGoodsnum {
  padding: 0px 8px;
  text-align: center;
}

.tGoodsmoney {
  width: 200px;
  text-align: center;
}

.tGoodsOp,
.LgoodsOp {
  padding-left: 15px;
  width: 140px;
}

.tdminH {
  height: 120px
}

.Lgoodsinfo {
  padding-left: 20px;

  .GoodsInfo {
    display: flex;
    align-items: center;
  }

  .GoodsInfoimg {
    width: 90px;
    height: 90px;
    background-color: #F9F9F9;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.Goodsimgs {
  display: flex;
  width: 80px;
  height: 80px;
  background-size: 100% 100%;

}

.Lgoodsprice {
  padding-left: 20px;
}

.Lgoodsnum {
  padding: 0px 8px;
  text-align: center;
}

.Lgoodsmoney {
  text-align: center;
}

.LgoodsOp {
  a {
    cursor: pointer;
  }
}

.bstylesW {
  height: 15px;
  line-height: normal;
  box-sizing: content-box;
  margin: 0 auto;
}

//////////@at-root
.borderstyles {
  border-radius: 2px;
  padding: 2px;
  border: 1px #3F61F6 solid;
  color: #3F61F6;
  text-align: center;
  font-size: 12px;
}

.borderstylesRed {
  border-radius: 2px;
  padding: 2px;
  color: #FF4B4A;
  border: 1px #FF4B4A solid;
  text-align: center;
  font-size: 12px;
}

.ttitle {
  font-size: 14px;
  font-family: MicrosoftYaHei;
  line-height: 17px;
  color: #333333;
  margin: 2px 0px;
  opacity: 1;
  text-overflow: ellipsis;
}

.tfont {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #C1C2C5;
  opacity: 1;
}

///////@at-root
.supplyList {
  height: 30px;
  margin: 10px 0px;
  border-bottom: 1px #E7EBEF dashed;
}

.supplyListprice {
  display: inline-block;
  width: 120px;
  margin: 0px 10px;
  font-size: 12px;
  font-family: MicrosoftYaHei;
}

.supplyListstock {
  display: inline-block;
  margin: 0 auto;
  color: $warningColor;
  font-size: 12px;
  font-family: MicrosoftYaHei;
}

.supplyNum {
  height: 30px;
  margin: 10px 0px;
}

.stockErr {
  margin: 10px 0px;
  color: $errorColor;
  font-size: 12px;
}


.nulltb {
  td {
    border: 0;
    text-align: center;
    padding-top: 30px;
  }
}