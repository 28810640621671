@import '../../../assets/scss/_base.scss';

.tips {
  height: 90px;
  background: #F3F6FF;
  display: flex;
  flex-direction: column;
  justify-content: center;

  div {
    margin-left: 30px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    letter-spacing: 1px;
  }

  .title {
    color: $errorColor;
    font-weight: bold;
  }

}

.costmoney {
  margin-top: 15px;
  height: 129px;
  border: 1px solid #D5D9DB;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .costLeft {
    margin-left: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .leftR {
      width: 50px;
      height: 50px;
      background: url('./../../../assets/images/accountM.png');
    }

    .leftM {
      margin-left: 15px;
    }
  }

  .costRight {
    margin-right: 60px;

    .Rightspan {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      line-height: 29px;
      color: $errorColor;
    }
  }
}

.footBtn {
  text-align: center;

  Button {
    margin: 20px 10px;
  }
}